:root {
  --medium-color: #3840d7;
  --strong-color: #3840d7;
}

.VibrationControl > .MuiDialog-container > .MuiDialog-paperWidthSm {
  max-width: 750px;
  border-radius: 15px;
}

.VibrationControl
  > .MuiDialog-container
  > .MuiPaper-root
  > .VibrationControl_container {
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #ffffff;
  border-radius: 25px;
  padding: 3rem 5rem;
  display: grid;
  gap: 2rem;
}

.VibrationControl > .MuiDialog-container > .MuiPaper-root > .homeScreenPopoup {
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #ffffff;
  padding: 2rem 2rem;
  display: grid;
  gap: 2rem;
  position: relative;
}

.vibration-level-medium {
  position: relative;
  margin-left: -140px;
  margin-right: 65px;
}
.vibration-level-medium .medium1,
.medium2 {
  position: absolute;
  width: 50px;
  height: 45px;
  margin-left: 10px;
  border: solid 3px #3840d7;
  border-color: var(--medium-color) transparent transparent transparent;
  border-radius: 60%/100% 100% 0 0;
  transform: rotate(90deg);
}
.vibration-level-medium .medium2 {
  width: 80px;
}

.vibration-level-strong {
  position: relative;
  margin-left: -140px;
  margin-right: 50px;
}
.vibration-level-strong .strong1,
.strong2,
.strong3 {
  position: absolute;
  height: 45px;
  margin-left: 10px;
  border: solid 3px #3840d7;
  border-color: var(--strong-color) transparent transparent transparent;
  border-radius: 60%/100% 100% 0 0;
  transform: rotate(90deg);
}
.vibration-level-strong .strong1 {
  width: 50px;
}
.vibration-level-strong .strong2 {
  width: 80px;
}
.vibration-level-strong .strong3 {
  width: 100px;
  margin-left: 15px;
}

.vibration-text p {
  font-family: "sequelSansMediumBody";
  font-weight: 310;
  font-size: 18px;
  color: #141e92;
  margin-bottom: 0;
}

.place-image {
  display: flex;
  justify-content: center;
}

.button_On {
  display: flex;
  justify-content: center;
}

.button_On button {
  width: 195px;
  height: 70px;
  color: #ffffff;
  background: #3840d7;
  border-radius: 32px;
  font-family: "sequelSansMediumBody";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
}

.button_On button:hover {
  background: #ededed;
  color: #1c2567;
}

.css-dmmspl-MuiFormGroup-root {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
}

.css-1f2kvjf-MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: #12157f;
  font-family: "sequelSansLightBody";
  font-weight: 900;
  font-size: 19px;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  top: 85px !important;
  left: 150px !important;
}
/* HOME SCREEN MODAL */
/* .popUp {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
} */

.popUp.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.popUp.exit {
  opacity: 0;
}

.popUp-content {
  width: 600px;
  background-color: #fff;
  border-radius: 29px;
  transition: all 0.3s ease-in-out;
  margin-top: 35px;
}

.popUp-panel {
  background-color: #ededed;
  width: 665px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popUp-panel-text {
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 500;
}

.popUp-body {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 15px 0px 15px;
}

.list {
  display: flex;
  text-align: start;
  width: 100%;
}

.animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.text {
  color: #141e92;
  font-weight: 500;
  font-size: 18px;
  line-height: normal;
  display: flex;
  flex-direction: column;
}

.text > li {
  padding-bottom: 10px;
}

.popUp-panel-factory {
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e86987;
}
.popUp-panel-factory > p {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  margin: 20px 0;
}
.factory-text > p {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.switch-bluetooth {
  display: flex;
  align-items: flex-end;
}

.bluetooth-on {
  display: flex;
  align-items: center;
  width: 40px;
  height: 20px;
  border: 1.5px solid #1c2567;
  border-radius: 10px;
  padding: 0 3px 0 3px;
  margin-left: 10px;
  animation: color 2.5s steps(1, end) infinite;
}

#on-off {
  margin-bottom: 0;
  margin-left: 10px;
  width: 19px;
  color: black;
}

#on-off::after {
  content: "";
  animation: switch 2.5s steps(1, end) infinite;
}

.switch-dot {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
  animation: move 2.5s steps(1, end) infinite;
}

.button-blue {
  width: 170px;
  height: 70px;
  background: #3840d7;
  color: #fff;
  border-radius: 32px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 22px;
  transition-duration: 0.2s;
  cursor: pointer;
}

.button-blue:hover {
  background-color: #ededed;
  color: #8589aa;
}

.button-blue:disabled {
  background-color: gray;
  color: white;
}

.onCodeResend:hover {
  text-decoration: underline;
}

.button-red {
  width: 170px;
  height: 70px;
  background: #e86987;
  color: #fff;
  border-radius: 32px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  transition-duration: 0.2s;
}
.button-red:hover {
  cursor: pointer;
  background-color: #ededed;
  color: #e86987;
}
.button-red:disabled {
  cursor: inherit;
  background-color: lightgray;
  color: gray;
}
.blue-red-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.practice-title > h3 {
  color: #3840d7;
  font-size: 36px;
  text-align: center;
}

@keyframes color {
  0%,
  100% {
    background-color: #fff;
  }

  50% {
    background-color: #1c2567;
  }
}

@keyframes move {
  0%,
  100% {
    transform: translateX(0px);
    background-color: #1c2567;
  }

  50% {
    transform: translateX(17px);
    background-color: #fff;
  }
}

@keyframes switch {
  0%,
  100% {
    content: "off";
  }

  50% {
    content: "on";
  }
}

.account-title > h3 {
  color: #142194;
  font-size: 36px;
  text-align: left;
  font-weight: bold;
}

.account-control {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0 1rem 0;
}

.account-control input {
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-weight: 500;
  outline: 0;
  border-width: 0 0 1px;
  border-color: #949494;
}

.account-control input::placeholder {
  font-weight: 300;
}

.account-control :focus {
  border-color: #18acfe;
}

.account-control label {
  font-size: 14px;
  color: #c1c1c1;
}

.account-error {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #ffe4e6;
  border-radius: 180px;
  margin-top: 15px;
}

.button-create {
  width: 100%;
  height: 70px;
  color: #ffffff;
  background: #3840d7;
  border-radius: 32px;
  font-family: "sequelSansMediumBody";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.button-create:hover {
  cursor: pointer;
}

.have-account {
  margin: 0 auto;
  padding-top: 1rem;
}

.have-account p {
  color: #949494;
  margin-bottom: 5px;
  font-size: 13px;
}

.forgot-password {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;

  display: grid;
  place-content: center;

  width: 16px;
  height: 16px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  margin-right: 8px;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #000;
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.css-1h7anqn {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
}

.footnote-wrap {
  margin-bottom: 20px;
}

.footnote {
  font-size: 11px;
  opacity: 0.8;
}

input:not([type="checkbox"]):-webkit-autofill,
input:not([type="checkbox"]):-webkit-autofill:hover,
input:not([type="checkbox"]):-webkit-autofill:focus,
input:not([type="checkbox"]):-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media only screen and (max-height: 700px) {
  .modalExampleImage {
    height: 150px;
  }

  .place-image img {
    height: 100px;
  }
}

.instructions-text {
  text-align: center;
}
