.loading{
    margin-top: 120px;
    height: 700px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inBlockClass{
    position: absolute;
    top: 50%;
    left: 50%;
    color: blue;
    z-index: 99;
}