* {
  margin: 0;
}

a {
  color: unset;
  text-decoration: unset;
}

a:hover {
  color: unset;
  text-decoration: unset;
}

html {
  margin: 0;
  height: 100%;
}

body {
  font-family: "sequelSansMediumBody";
  background-image: url("../public/assets/Images/mainbg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  background-color: currentcolor;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  overflow: initial;
}

#root {
  height: 100%;
  padding: 0;
}

main {
  height: calc(100% - 60px);
  min-width: 1300px;
}

@font-face {
  font-family: sequelSansBoldBody;
  src: url(../public/assets/fonts/Sequel_Sans_Bold_Body.ttf) format("opentype");
}

@font-face {
  font-family: sequelSansBookDisp;
  src: url(../public/assets/fonts/Sequel_Sans_Book_Disp.ttf) format("opentype");
}

@font-face {
  font-family: sequelSansHeavyBody;
  src: url(../public/assets/fonts/Sequel_Sans_Heavy_Body.ttf) format("opentype");
}

@font-face {
  font-family: sequelSansMediumBody;
  src: url(../public/assets/fonts/Sequel_Sans_Medium_Body.ttf)
    format("opentype");
}

@font-face {
  font-family: sequelSansLightBody;
  src: url(../public/assets/fonts/Sequel_Sans_Light_Body.ttf) format("opentype");
}

.css-hz1bth-MuiDialog-container {
  height: 770px !important;
  transition: none !important;
}

.css-zw3mfo-MuiModal-root-MuiDialog-root {
  overflow: auto;
  transition: none !important;
}
