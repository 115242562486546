.header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  min-width: 1300px;
  height: 60px;
}

.header_container>h3 {
  font-family: "sequelSansMediumBody";
  font-weight: 700;
  font-size: 28px;
  color: #ffffff;
  letter-spacing: 0.7px;
  text-align: center;
  line-height: 1.8rem;
}

.header_home {
  display: flex;
  min-width: fit-content;
  align-items: center;
  justify-content: start;
  width: 100%;
}

.accountCircle {
  margin-left: 20px;
}

@media only screen and (max-height: 680px) {
  .header_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    height: 40px;
  }

  .mainLogo {
    width: 110px;
  }

  .header_container>h3 {
    font-size: 20px;
  }
}

/* @media only screen and (max-width: 720px) {
  .header_container {
    margin: 0 1.5rem;
  }
}

@media only screen and (max-width: 850px) {
  .header_container h3 {
    font-size: 18px;
    line-height: 1rem;
  } 
}
*/